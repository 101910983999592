export const BLOCKS = {
	DOCUMENT: "document",
	PARAGRAPH: "paragraph",

	HEADING_1: "heading-1",
	HEADING_2: "heading-2",
	HEADING_3: "heading-3",
	HEADING_4: "heading-4",
	HEADING_5: "heading-5",
	HEADING_6: "heading-6",

	OL_LIST: "ordered-list",
	UL_LIST: "unordered-list",
	LIST_ITEM: "list-item",

	HR: "hr",
	QUOTE: "blockquote",

	EMBEDDED_ENTRY: "embedded-entry-block",
	EMBEDDED_ASSET: "embedded-asset-block",
};

export const INLINES = {
	HYPERLINK: "hyperlink",
	ENTRY_HYPERLINK: "entry-hyperlink",
	ASSET_HYPERLINK: "asset-hyperlink",
	EMBEDDED_ENTRY: "embedded-entry-inline",
};

export const MARKS = {
	BOLD: "bold",
	ITALIC: "italic",
	UNDERLINE: "underline",
	CODE: "code",
};

export const defaultNodeRenderers = {
	[BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
	[BLOCKS.HEADING_1]: (node, next) => `<h1>${next(node.content)}</h1>`,
	[BLOCKS.HEADING_2]: (node, next) => `<h2>${next(node.content)}</h2>`,
	[BLOCKS.HEADING_3]: (node, next) => `<h3>${next(node.content)}</h3>`,
	[BLOCKS.HEADING_4]: (node, next) => `<h4>${next(node.content)}</h4>`,
	[BLOCKS.HEADING_5]: (node, next) => `<h5>${next(node.content)}</h5>`,
	[BLOCKS.HEADING_6]: (node, next) => `<h6>${next(node.content)}</h6>`,
	[BLOCKS.EMBEDDED_ENTRY]: (node, next) => `<div>${next(node.content)}</div>`,
	[BLOCKS.UL_LIST]: (node, next) => `<ul>${next(node.content)}</ul>`,
	[BLOCKS.OL_LIST]: (node, next) => `<ol>${next(node.content)}</ol>`,
	[BLOCKS.LIST_ITEM]: (node, next) => `<li>${next(node.content)}</li>`,
	[BLOCKS.QUOTE]: (node, next) => `<blockquote>${next(node.content)}</blockquote>`,
	[BLOCKS.HR]: () => "<hr/>",
	[INLINES.ASSET_HYPERLINK]: (node) => defaultInline(INLINES.ASSET_HYPERLINK, node),
	[INLINES.ENTRY_HYPERLINK]: (node) => defaultInline(INLINES.ENTRY_HYPERLINK, node),
	[INLINES.EMBEDDED_ENTRY]: (node) => defaultInline(INLINES.EMBEDDED_ENTRY, node),
	[INLINES.HYPERLINK]: (node, next) => `<a href="${node.data.uri}">${next(node.content)}</a>`,
};

export const defaultMarkRenderers = {
	[MARKS.BOLD]: (text) => `<b>${text}</b>`,
	[MARKS.ITALIC]: (text) => `<i>${text}</i>`,
	[MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
	[MARKS.CODE]: (text) => `<code>${text}</code>`,
};

export const defaultInline = (type, node) =>
	`<span>type: ${type} id: ${node.data.target.sys.id}</span>`;
