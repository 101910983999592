<template>
	<div class="home">
		<ContentfulEntryProvider id="26S8vPNemL238nCoHnKt4m" :is-loading="isLoading">
			<template v-slot:default="{ entry }">
				<MainNavigation class="home__nav" />
				<div class="home__content">
					<ContentfulRenderer
						:elements="entry.elements"
						:component-options="renderComponentOptions"
					/>
				</div>
				<MainFooter />
			</template>
		</ContentfulEntryProvider>
	</div>
</template>

<script>
import ContentfulEntryProvider from "@/components/contentful/ContentfulEntryProvider.vue";
import ContentfulRenderer from "@/components/contentful/ContentfulRenderer.vue";
import MainNavigation from "@/components/navigation/MainNavigation.vue";
import MainFooter from "@/components/navigation/MainFooter.vue";
import { AnalyticsApi } from "@/api/analytics.api.js";

export default {
	name: "home",
	components: { MainFooter, ContentfulEntryProvider, ContentfulRenderer, MainNavigation },
	data: function () {
		return {
			isLoading: true,
		};
	},
	computed: {
		renderComponentOptions() {
			return {
				"home-flow-features": {
					columnVariant: "A",
					axisMax: { md: 2, lg: 4, xl: 4 },
					gap: { md: "20px", lg: "40px", xl: "40px" },
				},
				"home-flow-benefits": {
					axisMax: { md: 1, lg: 1, xl: 1 },
					gap: { md: "20px", lg: "40px", xl: "40px" },
				},
			};
		},
	},
	created() {
		const analytics = new AnalyticsApi();
		analytics.trackPage({ name: "/" });
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.home {
	min-width: 100%;
	min-height: 100vh;
	background-color: #f7f7ff;
}

.home__nav {
	position: relative;
	z-index: 1;
}

::v-deep(.intro-hero) {
	z-index: 0;
	margin-top: -140px;
	padding-top: 140px;
	padding-bottom: 80px;
	@include mq(lg) {
		min-height: 700px;
	}
}

::v-deep(.product-intro-hero) {
	@include mq(md) {
		min-height: 300px;
	}
	.hero__content {
		background-color: #383d4c;
	}
	.hero__text {
		color: rgba(#fff, 0.9);
	}
	.hero__text-detailed {
		color: rgba(#fff, 0.8);
		text-align: center;
		font-weight: 500;
		letter-spacing: -0.03em;
		padding-bottom: 20px;
		@include mq(lg) {
			font-weight: 400;
		}
	}
	.hero__buttons {
		display: none;
	}
}

::v-deep(#benefits-flow) {
	.contentful-flow__item {
		&:not(:last-of-type) {
			border-bottom: 1px dashed rgba(#fff, 0.12);
			@include mq(md) {
				border-bottom: none;
			}
		}
	}
}
</style>
