import { createApp } from "vue";
import App from "./App.vue";
import "./index.scss";
import ContentfulPlugin from "@/plugins/contentful/contentful.js";
import MediaQueryMixin from "@/mixins/mq";
import router from "@/routes";
import firebase from "firebase/app";
import { firebaseConfig } from "@/config/firebase.config.js";

const app = createApp(App);

// router
app.use(router);

// firebase
firebase.initializeApp(firebaseConfig);

// plugins
// contentful plugin
const contentfulComponentMap = {
	dynamicComponent: "contentful-dynamic-component",
	contact: "contact",
	flow: "flow",
	heading: "heading",
	headingGroup: "heading-group",
	hero: "hero",
	iconCard: "icon-card",
	image: "image",
	richText: "rich-text",
};

app.use(ContentfulPlugin, {
	componentMap: contentfulComponentMap,
});
// configure app
app.mixin(MediaQueryMixin); // media queries
// mount app - let's go
app.mount("#app");
