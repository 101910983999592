export class ResponsiveValues {
	constructor({ xs, sm, md, lg, xl }) {
		this.xs = xs;
		this.sm = sm;
		this.md = md;
		this.lg = lg;
		this.xl = xl;
	}

	filled() {
		const rv = new ResponsiveValues({});
		let val;
		["xs", "sm", "md", "lg", "xl"].forEach((size) => {
			val = this[size] || val;
			rv[size] = val;
		});
		return rv;
	}

	toCSSVars(prefix) {
		const vars = {};
		let val;
		["xs", "sm", "md", "lg", "xl"].forEach((size) => {
			val = this[size] || val;
			vars[`${prefix}-${size}`] = val;
		});
		return vars;
	}
}
