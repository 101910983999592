import Breakpoints from "@/assets/styles/exports/_breakpoints.scss";
import { defineComponent } from "vue";

/** mixin to test for responsive breakpoints */
export default defineComponent({
	data() {
		return {
			breakpoints: {},
			windowWidth: 0,
			windowHeight: 0,
			isXs: false,
			isSm: false,
			isMd: false,
			isLg: false,
			isXl: false,
			abbrSize: undefined,
			abbrWeight: undefined,
		};
	},
	computed: {
		mq() {
			return this;
		},
		abbr() {
			return this.abbrSize;
		},
	},
	methods: {
		/**
		 * @returns {default.computed.abbrSize} The current size abbreviation (i.e.: xs, sm, etc)
		 */
		mqabbr() {
			return this.abbr;
		},
		mqval(defaultVal, ...vals) {
			if (this.isXs) return defaultVal;
			else if (this.isSm) return vals[0] || defaultVal;
			else if (this.isMd) return vals[1] || vals[0] || defaultVal;
			else if (this.isLg) return vals[2] || vals[1] || vals[0] || defaultVal;
			else if (this.isXl) return vals[3] || vals[2] || vals[1] || vals[0] || defaultVal;
			else return defaultVal;
		},
		mqlt(size) {
			return this.windowWidth < this.breakpoints[size];
		},
		mqlte(size) {
			return this.windowWidth <= this.breakpoints[size];
		},
		mqgt(size) {
			return this.windowWidth > this.breakpoints[size];
		},
		mqgte(size) {
			return this.windowWidth >= this.breakpoints[size];
		},

		/**
		 * Update internal state we use to determine the screen
		 * @private
		 */
		_updateInternals() {
			this.windowWidth = document.documentElement.clientWidth;
			this.windowHeight = document.documentElement.clientHeight;
			// store which breakpoint it is at
			this.isXs = this.windowWidth < this.breakpoints["sm"];
			this.isSm =
				this.windowWidth >= this.breakpoints["sm"] && this.windowWidth < this.breakpoints["md"];
			this.isMd =
				this.windowWidth >= this.breakpoints["md"] && this.windowWidth < this.breakpoints["lg"];
			this.isLg =
				this.windowWidth >= this.breakpoints["lg"] && this.windowWidth < this.breakpoints["xl"];
			this.isXl = this.windowWidth >= this.breakpoints["xl"];
			// store the abbreviated size
			this._cacheAbbrInfo();
		},
		_cacheAbbrInfo() {
			this.abbrSize = "xs";
			this.abbrWeight = 0;
			if (this.isSm) {
				this.abbrSize = "sm";
				this.abbrWeight = 1;
			} else if (this.isMd) {
				this.abbrSize = "md";
				this.abbrWeight = 2;
			} else if (this.isLg) {
				this.abbrSize = "lg";
				this.abbrWeight = 3;
			} else if (this.isXl) {
				this.abbrSize = "xl";
				this.abbrWeight = 4;
			}
		},
	},
	created() {
		// convert breakpoints
		const keys = Object.keys(Breakpoints);
		for (const idx in keys) {
			const bp = keys[idx];
			const strVal = Breakpoints[bp];
			if (strVal === "0") {
				this.breakpoints[bp] = 0;
			} else {
				this.breakpoints[bp] = Number.parseInt(strVal.slice(0, -2), 10);
			}
		}
	},

	mounted() {
		if (this.breakpoints) {
			this.$nextTick(() => {
				// update state now
				this._updateInternals();
				// register a listener to update the state when the screen size changes
				window.addEventListener("resize", this._updateInternals);
			});
		}
	},
	beforeUnmount() {
		// remove the window resize listener
		window.removeEventListener("resize", this._updateInternals);
	},
});
