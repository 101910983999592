<template>
	<div v-if="isEntryLoading">
		<slot name="loading"></slot>
	</div>
	<div v-else>
		<slot :entry="entry"></slot>
	</div>
</template>

<script>
export default {
	name: "contentful-entry-provider",
	emits: ["update:isLoading"],
	props: {
		id: {
			type: String,
			required: true,
		},
		isLoading: {
			type: Boolean,
		},
	},
	data: function () {
		return {
			entry: undefined,
			isEntryLoading: true,
		};
	},
	async created() {
		this.isEntryLoading = true;
		this.$emit("update:isLoading", true);
		let result;
		try {
			result = await this.$contentful.fetchEntity(this.id);
			this.entry = result.entry;
		} catch (e) {
			console.error(e);
		}
		this.isEntryLoading = false;
		this.$emit("update:isLoading", false);
	},
};
</script>
