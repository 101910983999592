import { _normalize, _walkEntry } from "./parsing.js";

export async function _fetchEntity(contentful, id, params, options) {
	const path = `/spaces/${process.env.VUE_APP_CTF_SPACE_ID}/environments/master/entries`;
	const resParams = {
		"sys.id": id,
		...params,
	};
	const res = await contentful.httpClient.get(path, {
		params: resParams,
	});

	const { items, includes } = res.data;
	if (
		!items ||
		items.length === 0 ||
		(items.length === 1 && items[0].sys && items[0].sys.id !== id)
	) {
		throw new Error("Entry not found");
	}

	const transformer = contentful.options.transformer || _normalize;
	let transformedItems = items;
	transformedItems = transformedItems.map((item) => transformer(item, options));
	const includeOptions = {
		isInclude: true,
		...options,
	};
	const transformedIncludes = Object.keys(includes || {}).reduce(
		(all, type) => [...all, ...includes[type].map((item) => transformer(item, includeOptions))],
		[],
	);
	const includesMap = new Map(transformedIncludes.map((entity) => [entity._mapKey, entity]));
	transformedIncludes.forEach((item) => {
		_walkEntry(item, includesMap, options);
	});
	transformedItems.forEach((item) => {
		_walkEntry(item, includesMap, options);
	});
	return { res, entry: transformedItems[0] };
}
