<template>
	<div class="hero" :style="contentStyles" :class="heroClasses">
		<div class="hero__overlay" v-if="hasBackgroundImage"></div>
		<div class="hero__content fyr-container fyr-container-px">
			<h1 class="hero__text" v-html="htmlText"></h1>
			<h2 class="hero__text-detailed" v-html="htmlTextDetailed"></h2>
		</div>
		<div class="hero__accessory" v-if="showAccessory">
			<img src="~@/assets/images/ic_scroll_text_w.svg" alt="Please scroll down" />
		</div>
	</div>
</template>

<script>
import { documentToHtmlString } from "@/plugins/contentful/rendering/render_functions.js";

export default {
	name: "block-hero",
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		contentStyles() {
			const styles = {};
			if (this.componentData) {
				if (this.componentData.textColor) {
					styles["color"] = this.componentData.textColor;
				} else {
					styles["color"] = "#fff";
				}
				if (this.componentData.backgroundColor) {
					styles["background-color"] = this.componentData.backgroundColor;
				}
				if (this.componentData.image) {
					styles["background-image"] = `url(${this.componentData.image.file.url})`;
					styles["background-size"] = "cover";
					styles["background-position"] = "center center";
				}
			}
			return styles;
		},

		hasBackgroundImage() {
			return !!(this.componentData && this.componentData.image);
		},

		heroClasses() {
			return this.componentData && this.componentData.classes;
		},

		htmlText() {
			return (
				this.componentData &&
				this.componentData.text &&
				documentToHtmlString(this.componentData.text)
			);
		},

		htmlTextDetailed() {
			return (
				this.componentData &&
				this.componentData.detailedText &&
				documentToHtmlString(this.componentData.detailedText)
			);
		},

		showAccessory() {
			return this.componentData && this.componentData.showAccessory;
		},
	},
	methods: {
		onButtonClicked() {
			this.$router.push({ name: "contact" });
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.hero {
	position: relative;
	min-height: 400px;
	@include mq(sm) {
		min-height: 600px;
	}
	@include mq(lg) {
		height: 95vh;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	transition: background-image 2s;
	padding-bottom: 50px;

	&-compact {
		min-height: auto;
		padding: 80px 0;
		@include mq(lg) {
			height: unset;
			padding: 120px 0;
		}
		.hero__content {
			.hero__text {
				::v-deep(p) {
					font-family: "Poppins", sans-serif;
					$fs: calc(20px + (54 - 20) * ((100vw - 320px) / (1280 - 320)));
					font-size: $fs;
					font-weight: 500;
					line-height: 0.95em;
					@include mq(xl) {
						font-size: 54px;
					}
				}
				::v-deep(b) {
					font-weight: 600;
					color: #fff;
				}
			}
		}
	}

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(#000, 0.42);
		z-index: 0;
	}
	&__content {
		height: 100%;
		flex: 1;
		z-index: 2;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
	&__text {
		z-index: 2;
		user-select: none;
		::v-deep(p) {
			font-family: "Inter", sans-serif;
			letter-spacing: -0.03em;
			line-height: 0.85em;
			font-weight: 700;
			// font size is dynamic
			$fs: calc(30px + (90 - 30) * ((100vw - 320px) / (1280 - 320)));
			font-size: $fs;
			@include mq(xl) {
				font-size: 90px;
			}
		}
	}

	&__text-detailed {
		user-select: none;
		margin-top: 10px;
		// font size is dynamic
		$fs: calc(16px + (30 - 16) * ((100vw - 320px) / (1280 - 320)));
		font-size: $fs;
		letter-spacing: -0.02em;
		font-weight: 400;
		@include mq(xl) {
			font-size: 30px;
		}
		::v-deep(p) {
			font-family: "Poppins", sans-serif;
		}
		::v-deep(b) {
			font-weight: 600;
			color: #fff;
		}
	}
	&__buttons {
		margin-top: 35px;
		z-index: 2;
		width: 100%;
		::v-deep(.foyyr-button) {
			color: #222;
			background-color: rgba(#fff, 0.65);
			border: 2px solid rgba(#fff, 0.7);
			&:hover {
				color: #222;
				background-color: rgba(#fff, 0.85);
				border: 2px solid rgba(#fff, 0.95);
			}
		}
	}
	&__accessory {
		position: absolute;
		bottom: 20px;
		right: 30px;
		display: none;
		@include mq(lg) {
			display: inline-block;
		}
		img {
			height: 70px;
		}
	}
}
</style>
