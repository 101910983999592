<template>
	<div class="contact-form" v-if="!isDone">
		<div class="contact-form__label">Name</div>
		<div class="contact-form__input">
			<input ref="name" type="text" autocomplete="given-name" placeholder="Enter your name" />
		</div>
		<div class="contact-form__label">Email</div>
		<div class="contact-form__input">
			<input ref="email" type="email" placeholder="Enter your email" autocomplete="email" />
		</div>
		<div class="contact-form__label">Business name (optional)</div>
		<div class="contact-form__input">
			<input
				ref="businessName"
				type="text"
				placeholder="Enter your Business Name"
				autocomplete="organization"
			/>
		</div>
		<div class="contact-form__label">Your message or extra details (optional)</div>
		<div class="contact-form__input">
			<textarea ref="message" placeholder="Enter your message / details" rows="6"></textarea>
		</div>
		<div class="contact-form__actions contact-form__actions--right">
			<div v-if="isSubmitting" class="contact-form__info-message">
				Submitting your info. Please wait ...
			</div>
			<div
				v-else-if="hasErrors"
				class="contact-form__info-message contact-form__info-message--error"
			>
				Oops. You forgot to enter a required value.
			</div>
			<button ref="submitButton" @click="onSubmit">Submit</button>
		</div>
	</div>
	<div v-else>
		<div class="contact-thanks__heading">Thanks for your interest!</div>
		<div class="contact-thanks__body">
			We'll get back to you as soon as possible with more details. In the meantime, if you have
			further questions, please email us at <a href="mailto:info@foyyr.com">info@foyyr.com</a>.
		</div>
	</div>
</template>

<script>
import { Contact, ContactApi, ContactMessage } from "@/api/contact.api.js";

export default {
	name: "contact-form",
	data: function () {
		return {
			isSubmitting: false,
			isDone: false,
			hasErrors: false,
			errors: {},
		};
	},
	methods: {
		onSubmit() {
			const name = this.$refs.name.value;
			const email = this.$refs.email.value;
			const businessName = this.$refs.businessName.value;
			const message = this.$refs.message.value;
			const hasRequired = name && email && message;
			if (!hasRequired) {
				this.hasErrors = true;
				this.$refs.name.classList.remove("error");
				this.$refs.email.classList.remove("error");
				this.$refs.message.classList.remove("error");
				if (!name) {
					this.$refs.name.classList.add("error");
				}
				if (!email) {
					this.$refs.email.classList.add("error");
				}
				if (!message) {
					this.$refs.message.classList.add("error");
				}
				return;
			}
			this.$refs.submitButton.disabled = true;
			this.isSubmitting = true;
			const api = new ContactApi();
			try {
				const res = api.saveMessage(
					new ContactMessage(new Contact(name, email, businessName), message),
				);
				this.hasErrors = res.errors;
				this.isDone = !this.hasErrors;
			} catch (e) {
				console.error(e);
				this.hasErrors = true;
			}
			this.isSubmitting = false;
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.error {
	border: 1px solid #ff006f !important;
	outline-color: #ff006f !important;
}

.contact-form {
	font-family: "Inter", sans-serif;
	&__label {
		font-family: "Inter", sans-serif;
		text-transform: uppercase;
		font-size: 0.85rem;
		font-weight: 500;
		margin-bottom: 5px;
		color: #61617b;
	}
	&__input {
		display: flex;
		flex-direction: row;
		align-items: center;
		&:not(:last-of-type) {
			margin-bottom: 20px;
		}
		input {
			font-family: "Inter", sans-serif;
			height: 44px;
			width: 100%;
			border: 1px solid rgba(#c4c4d3, 0.6);
			padding: 0 10px;
			border-radius: 3px;
			outline-color: #ff9018;
			outline-width: 2px;
			&:focus {
				border: 1px solid #ff9018;
			}
			&:active {
				border: 1px solid #ff9018;
			}
		}
		textarea {
			font-family: "Inter", sans-serif;
			width: 100%;
			border: 1px solid rgba(#c4c4d3, 0.6);
			padding: 10px;
			border-radius: 3px;
			outline-color: #ff9018;
			&:focus {
				border: 1px solid #ff9018;
			}
			&:active {
				border: 1px solid #ff9018;
			}
		}
	}
	&__actions {
		display: flex;
		flex-direction: column;
		align-items: center;
		@include mq(md) {
			flex-direction: row;
			align-items: center;
		}
		&--right {
			justify-content: flex-end;
		}
		button {
			display: inline-block;
			font-family: "Inter", sans-serif;
			text-transform: uppercase;
			font-size: 0.85em;
			min-height: 44px;
			min-width: 100%;
			font-weight: 600;
			background-color: #fafafa;
			border: 1px solid rgba(#c4c4d3, 0.6);
			border-radius: 3px;
			cursor: pointer;
			@include mq(md) {
				min-width: 120px;
			}
			&:focus {
				border: 1px solid #ff9018;
			}
			&:active {
				border: 1px solid #ff9018;
			}
			&:disabled {
				border: 1px solid #ddd;
			}
		}
	}

	&__info-message {
		font-size: 0.9em;
		order: 2;
		@include mq(md) {
			order: 0;
		}
		margin-top: 20px;
		@include mq(md) {
			margin-right: 20px;
			margin-top: 0;
		}
		&--error {
			color: #ff006f;
		}
	}
}

.contact-thanks {
	font-family: "Inter", sans-serif;
	&__heading {
		margin-top: 3rem;
		margin-bottom: 0.5rem;
		font-family: "Poppins", sans-serif;
		font-size: 2em;
		text-align: center;
		color: #17a870;
		font-weight: 600;
		letter-spacing: -0.03em;
	}
	&__body {
		font-family: "Inter", sans-serif;
		text-align: center;
		color: #136345;
		a {
			color: #0a7d50;
			font-weight: 600;
		}
	}
}
</style>
