<template>
	<div v-for="(c, k) in elements" :key="k">
		<ContentfulDynamicComponent
			:type="c._type"
			:component-data="c"
			:component-options="componentOptions"
		/>
	</div>
</template>

<script>
import ContentfulDynamicComponent from "@/components/contentful/ContentfulDynamicComponent.vue";

export default {
	name: "contentful-renderer",
	components: {
		ContentfulDynamicComponent,
	},
	props: {
		elements: {
			required: true,
		},
		componentOptions: undefined,
	},
};
</script>

<style scoped></style>
