<template>
	<component
		v-if="componentForType(componentType)"
		:is="componentForType(componentType)"
		:component-data="componentData"
		:component-options="optionsForType(componentType)"
	/>
</template>

<script>
import Contact from "@/components/contentful/blocks/Contact.vue";
import Flow from "@/components/contentful/blocks/Flow.vue";
import Heading from "@/components/contentful/blocks/Heading.vue";
import HeadingGroup from "@/components/contentful/blocks/HeadingGroup.vue";
import Hero from "@/components/contentful/blocks/Hero.vue";
import IconCard from "@/components/contentful/blocks/IconCard.vue";
import Image from "@/components/contentful/blocks/ImageHolder.vue";
import RichText from "@/components/contentful/blocks/RichText.vue";

export default {
	name: "contentful-dynamic-component",
	components: {
		Contact,
		Flow,
		Heading,
		HeadingGroup,
		Hero,
		IconCard,
		Image,
		RichText,
	},
	props: {
		type: {
			type: String,
		},
		componentData: {
			required: true,
		},
		componentOptions: undefined,
	},
	computed: {
		componentType() {
			return this.type || this.componentData.component || this.componentData._type;
		},
		elements() {
			return [this.componentData];
		},
	},
	methods: {
		componentForType(type) {
			return this.$contentful.componentForType(type);
		},
		optionsForType(type) {
			return (this.componentOptions && this.componentOptions[this.componentForType(type)]) || {};
		},
	},
};
</script>
