<template>
	<div class="main-navigation fyr-container fyr-container-px">
		<Logo />
	</div>
</template>

<script>
import Logo from "../content/Logo.vue";
export default {
	name: "main-navigation",
	components: { Logo },
};
</script>

<style scoped>
.main-navigation {
	display: flex;
	flex-direction: row;
	align-items: center;
	background: transparent;
	min-height: 140px;
}
</style>
