import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.afterEach((to, from, failure) => {
	if (!failure) {
		document.title = to.meta.title ? `${to.meta.title} | FOYYR` : "Cashback to the trade | FOYYR";
	}
});

export default router;
