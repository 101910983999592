export const Direction = {
	horizontal: 0,
	vertical: 1,
};

export function isHorizontalDirection(direction) {
	if (typeof direction === "number") {
		return direction === Direction.horizontal;
	}
	return direction && ["horizontal", "row"].indexOf(direction.toLowerCase()) !== -1;
}
