import axios from "axios";
import { inject } from "vue";
import { _fetchEntity } from "./fetch.js";

class Contentful {
	constructor(options) {
		this.options = options;
		this.httpClient = axios.create({
			baseURL: "https://cdn.contentful.com",
			headers: {
				Authorization: `Bearer ${process.env.VUE_APP_CTF_CD_ACCESS_TOKEN}`,
			},
			params: {
				include: (options.fetchOptions && this.fetchOptions.include) || 5,
			},
		});
	}

	// fetch functions
	async fetchEntity(id, params, options) {
		return _fetchEntity(this, id, params, options);
	}

	// component helpers
	componentForType(type) {
		return this.options.componentMap[type];
	}
}

// get the global contentful instance via an inject into the setup
export const ContentfulInstanceSymbol = Symbol();
export function useContentful() {
	const contentful = inject(ContentfulInstanceSymbol);
	if (!contentful) {
		throw new Error("Contentful instance is not configured correctly");
	}
	return contentful;
}

export default {
	install: (app, options) => {
		const contentful = new Contentful(options);
		app.config.globalProperties.$contentful = contentful;
		app.provide(ContentfulInstanceSymbol, contentful);
	},
};
