<template>
	<div class="flow" :class="classes" :style="styles">
		<slot></slot>
	</div>
</template>

<script>
import { Direction, isHorizontalDirection } from "@/constants/geometry";

export default {
	name: "flow",
	props: {
		direction: {
			type: [Number, String],
			default: Direction.horizontal,
			validator(value) {
				if (!value) {
					return true;
				}
				if (value instanceof Number) {
					return [Direction.horizontal, Direction.vertical].indexOf(value) !== -1;
				}
				return ["horizontal", "vertical", "row", "column"].indexOf(value.toLowerCase()) !== -1;
			},
		},
		axisMax: {
			type: [Number, String],
			default: 0,
		},
		axisSize: {
			default: "auto",
		},
		horizontalGap: {
			default: "20px",
		},
		sizingTemplate: {
			type: String,
		},
		verticalGap: {
			default: "20px",
		},
	},
	computed: {
		classes() {
			const classes = [];
			const orientation = isHorizontalDirection(this.direction) ? "horizontal" : "vertical";
			classes.push(`flow-${orientation}${this.axisMax > 0 ? "-fixed" : ""}`);
			return classes;
		},
		styles() {
			let sizeTemplate;
			if (this.sizingTemplate) {
				sizeTemplate = this.sizingTemplate;
			} else if (this.axisMax > 0) {
				sizeTemplate = `repeat(${this.axisMax}, ${this.axisSize})`;
			} else {
				sizeTemplate = "auto";
			}
			const styles = {};
			if (isHorizontalDirection(this.direction)) {
				styles["grid-template-columns"] = sizeTemplate;
			} else {
				styles["grid-template-rows"] = sizeTemplate;
			}
			styles["grid-column-gap"] = this.horizontalGap;
			styles["grid-row-gap"] = this.verticalGap;
			return styles;
		},
	},
};
</script>

<style scoped lang="scss">
.flow {
	display: grid;
	align-items: start;
	justify-content: start;
	&-horizontal {
		grid-auto-flow: column;
	}
	&-vertical {
		grid-auto-flow: row;
	}
	&-vertical-fixed {
		grid-auto-flow: row;
	}
}
</style>
