<template>
	<div class="icon-card">
		<div class="icon-card__content">
			<div class="icon-card__title">
				{{ componentData.title }}
			</div>
			<div class="icon-card__icon">
				<img :src="iconURL" :alt="iconAlt" :title="iconAlt" />
			</div>
			<div class="icon-card__subtitle">
				{{ componentData.subtitle }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "block-icon-card",
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		icon() {
			return this.componentData && this.componentData.icon;
		},

		iconURL() {
			return this.icon && this.icon.file && this.icon.file.url;
		},

		iconAlt() {
			return this.icon && this.componentData.iconAltText;
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.icon-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 30px 0 60px;
	&.contentful-flow__item-last {
		padding-bottom: 20px;
	}
	@include mq(md) {
		border-bottom: 0;
		padding-bottom: 10px;
	}
	@include mq(lg) {
		min-height: 260px;
		padding-bottom: 60px;
	}
	background-color: #222;
	color: #eee;
	text-align: center;

	&__icon {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 64px;
		margin-top: 20px;
		img {
			width: 100%;
			height: 100%;
		}
	}

	&__content {
		flex: 1;
		display: grid;
		grid-template-rows: 40px auto;
		@include mq(lg) {
			grid-template-rows: 80px auto;
		}
	}

	&__title {
		font-family: "Poppins", sans-serif;
		font-size: 1.4rem;
		line-height: 1.5rem;
		@include mq(md) {
			font-size: 1.45rem;
		}
		@include mq(lg) {
			font-size: 1.6rem;
			line-height: 1.6rem;
		}
		@include mq(lg) {
			font-size: 1.7rem;
			line-height: 1.7rem;
		}
		letter-spacing: -0.04em;
		font-weight: 700;
		align-self: end;
	}
	&__subtitle {
		color: rgba(#fff, 0.7);
		margin-top: 1rem;
		font-size: 0.95rem;
		letter-spacing: -0.02em;
		line-height: 1.1rem;
		align-self: start;
	}
}
</style>
