<template>
	<div class="heading" :style="cssVars">
		<div :class="containerClasses">
			<component :is="headingType">{{ text }}</component>
		</div>
	</div>
</template>

<script>
import { ResponsiveValues } from "@/components/contentful/facets/padding.js";

export default {
	name: "block-heading",
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		backgroundColor() {
			return this.componentData && this.componentData.backgroundColor;
		},
		containerClasses() {
			const classes = [];
			let useStandardContainer = true;
			if (this.componentData && this.componentData.useStandardContainer !== undefined) {
				useStandardContainer = this.componentData.useStandardContainer === true;
			}
			if (useStandardContainer) {
				classes.push("fyr-container", "fyr-container-px");
			}
			return classes;
		},
		cssVars() {
			let cssVars = {};
			if (this.textColor) {
				cssVars["--fyr-heading-text-color"] = this.textColor;
			}
			if (this.backgroundColor) {
				cssVars["--fyr-heading-bg-color"] = this.backgroundColor;
			}
			if (this.padding) {
				cssVars = { ...cssVars, ...this.padding.toCSSVars("--fyr-heading-pad") };
			}
			cssVars["--fyr-heading-text-align"] = this.textAlign || "center";
			return cssVars;
		},
		padding() {
			const val = this.componentData && this.componentData.padding;
			if (val) {
				return new ResponsiveValues(val);
			}
			return null;
		},
		text() {
			return this.componentData && this.componentData.text;
		},
		textAlign() {
			return this.componentData && this.componentData.textAlign;
		},
		textColor() {
			return this.componentData && this.componentData.textColor;
		},
		variant() {
			return this.componentData && this.componentData.variant;
		},
		headingType() {
			if (this.variant && this.variant === "small") {
				return "h2";
			}
			return "h1";
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.heading {
	background-color: var(--fyr-heading-bg-color);
	padding: var(--fyr-heading-pad-xs);
	@include mq(sm) {
		padding: var(--fyr-heading-pad-sm);
	}
	@include mq(md) {
		padding: var(--fyr-heading-pad-md);
	}
	@include mq(lg) {
		padding: var(--fyr-heading-pad-lg);
	}
	@include mq(xl) {
		padding: var(--fyr-heading-pad-xl);
	}
}

h1 {
	font-family: "Poppins", sans-serif;
	font-size: 1.6rem;
	line-height: 1.5rem;
	text-align: var(--fyr-heading-text-align);
	color: var(--fyr-heading-text-color);
	@include mq(md) {
		font-size: 2rem;
		line-height: 2rem;
	}
	@include mq(lg) {
		font-size: 2.4rem;
		line-height: 2.2rem;
	}
	letter-spacing: -0.04em;
	font-weight: 700;
	align-self: end;
}

h2 {
	font-family: "Inter", sans-serif;
	font-size: 1.1rem;
	line-height: 1.15rem;
	text-align: var(--fyr-heading-text-align);
	color: var(--fyr-heading-text-color);
	@include mq(md) {
		font-size: 1.15rem;
	}
	@include mq(lg) {
		font-size: 1.3rem;
		line-height: 1.35rem;
	}
	letter-spacing: -0.03em;
	font-weight: 500;
	align-self: end;
}
</style>
