<template>
	<div class="contact">
		<div class="contact__container fyr-container fyr-container-px">
			<ContactForm class="contact__form" />
		</div>
	</div>
</template>

<script>
import ContactForm from "@/components/forms/ContactForm.vue";
export default {
	name: "block-contact",
	components: { ContactForm },
	props: {
		componentData: undefined,
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.contact {
	background-color: #f7f7ff;
	min-height: 300px;
	&__container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
	&__form {
		margin: 50px 0 70px;
		width: 100%;
		@include mq(md) {
			width: 70%;
		}
		@include mq(lg) {
			width: 60%;
		}
		@include mq(xl) {
			width: 50%;
		}
	}
}
</style>
