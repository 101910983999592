<template>
	<div class="contentful-flow" :id="key" :style="cssVars">
		<div class="fyr-container fyr-container-px">
			<Flow
				class="contentful-flow__container"
				:direction="direction"
				:axis-max="columns"
				axis-size="1fr"
				:horizontal-gap="horizontalGap"
				:vertical-gap="verticalGap"
			>
				<div v-for="(c, k) in componentData.elements" :key="k" class="contentful-flow__item">
					<component
						:is="componentForType(c._type)"
						:component-data="c"
						:class="itemPositionClasses(k)"
						:component-options="componentOptions"
					/>
				</div>
			</Flow>
		</div>
	</div>
</template>

<script>
import Flow from "@/components/layouts/Flow.vue";
import IconCard from "@/components/contentful/blocks/IconCard.vue";
import Heading from "@/components/contentful/blocks/Heading.vue";
import RichText from "@/components/contentful/blocks/RichText.vue";
import { ResponsiveValues } from "@/components/contentful/facets/padding.js";
export default {
	name: "block-flow",
	components: { Flow, Heading, IconCard, RichText },
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		axisMax() {
			return (
				(this.componentOptions &&
					this.componentOptions.axisMax &&
					this.componentOptions.axisMax[this.mqabbr()]) ||
				1
			);
		},
		backgroundColor() {
			return this.componentData && this.componentData.backgroundColor;
		},
		columns() {
			const val = this.componentData && this.componentData.columns;
			if (val) {
				return new ResponsiveValues(val)[this.mqabbr()];
			}
			return null;
		},
		cssVars() {
			let cssVars = {};
			cssVars["--fyr-contentful-flow-background-color"] = this.backgroundColor || "#222";
			if (this.padding) {
				cssVars = { ...cssVars, ...this.padding.toCSSVars("--fyr-contentful-flow-pad") };
			}
			return cssVars;
		},
		direction() {
			const direction = this.componentData && this.componentData.direction;
			if (direction) {
				return direction.toLowerCase();
			}
			return null;
		},
		horizontalGap() {
			const val = this.componentData && this.componentData.horizontalGap;
			if (val) {
				return new ResponsiveValues(val).filled()[this.mqabbr()];
			}
			return null;
		},
		key() {
			return this.componentData && this.componentData.key;
		},
		padding() {
			const val = this.componentData && this.componentData.padding;
			if (val) {
				return new ResponsiveValues(val);
			}
			return null;
		},
		verticalGap() {
			const val = this.componentData && this.componentData.verticalGap;
			if (val) {
				return new ResponsiveValues(val).filled()[this.mqabbr()];
			}
			return null;
		},
	},
	methods: {
		componentForType(type) {
			return this.$contentful.componentForType(type);
		},
		itemPositionClasses(index) {
			if (index === 0) {
				return ["contentful-flow__item-first"];
			} else if (index === this.componentData.elements.length - 1) {
				return ["contentful-flow__item-last"];
			} else {
				return [];
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/mq";

.contentful-flow {
	background-color: var(--fyr-contentful-flow-background-color);
}

.contentful-flow__container {
	padding: var(--fyr-contentful-flow-pad-xs);
	@include mq(sm) {
		padding: var(--fyr-contentful-flow-pad-sm);
	}
	@include mq(md) {
		padding: var(--fyr-contentful-flow-pad-md);
	}
	@include mq(lg) {
		padding: var(--fyr-contentful-flow-pad-lg);
	}
	@include mq(xl) {
		padding: var(--fyr-contentful-flow-pad-xl);
	}
}

.contentful-flow__item {
	&.show-dividers {
		&:not(:last-of-type) {
			border-bottom: 1px solid var(--fyr-contentful-flow-divider-color);
			@include mq(md) {
				border-bottom: 0;
			}
		}
	}
}
</style>
