import { BLOCKS, defaultMarkRenderers, defaultNodeRenderers, INLINES } from "./render_types.js";

export function documentToHtmlString(richTextDocument, options = {}) {
	if (!richTextDocument || !richTextDocument.content) {
		return "";
	}

	return nodeListToHtmlString(richTextDocument.content, {
		renderNode: {
			...defaultNodeRenderers,
			...options.renderNode,
		},
		renderMark: {
			...defaultMarkRenderers,
			...options.renderMark,
		},
	});
}

function nodeListToHtmlString(nodes, { renderNode, renderMark }) {
	return nodes.map((node) => nodeToHtmlString(node, { renderNode, renderMark })).join("");
}

function nodeToHtmlString(node, { renderNode, renderMark }) {
	if (isText(node)) {
		const nodeValue = escapeHtml(node.value);
		if (node.marks.length > 0) {
			return node.marks.reduce((value, mark) => {
				if (!renderMark[mark.type]) {
					return value;
				}
				return renderMark[mark.type](value);
			}, nodeValue);
		}
		return nodeValue;
	} else {
		const nextNode = (nodes) => nodeListToHtmlString(nodes, { renderMark, renderNode });
		if (!node.nodeType || !renderNode[node.nodeType]) {
			// Node is unrecognized so we can't render anything.
			return "";
		}
		return renderNode[node.nodeType](node, nextNode);
	}
}

/**
 * Checks if the node is an instance of Inline.
 */
export function isInline(node) {
	return Object.values(INLINES).includes(node.nodeType);
}

/**
 * Checks if the node is an instance of Block.
 */
export function isBlock(node) {
	return Object.values(BLOCKS).includes(node.nodeType);
}

/**
 * Checks if the node is an instance of Text.
 */
export function isText(node) {
	return node.nodeType === "text";
}

var matchHtmlRegExp = /["'&<>]/;

function escapeHtml(string) {
	var str = "" + string;
	var match = matchHtmlRegExp.exec(str);

	if (!match) {
		return str;
	}

	var escape;
	var html = "";
	var index = 0;
	var lastIndex = 0;

	for (index = match.index; index < str.length; index++) {
		switch (str.charCodeAt(index)) {
			case 34: // "
				escape = "&quot;";
				break;
			case 38: // &
				escape = "&amp;";
				break;
			case 39: // '
				escape = "&#39;";
				break;
			case 60: // <
				escape = "&lt;";
				break;
			case 62: // >
				escape = "&gt;";
				break;
			default:
				continue;
		}

		if (lastIndex !== index) {
			html += str.substring(lastIndex, index);
		}

		lastIndex = index + 1;
		html += escape;
	}

	return lastIndex !== index ? html + str.substring(lastIndex, index) : html;
}
