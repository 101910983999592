<template>
	<footer class="footer">
		<div class="footer__content fyr-container fyr-container-px">
			<div class="footer__email">
				<a href="mailto:info@foyyr.com">info@foyyr.com</a>
			</div>
			<div class="footer__links">
				<a href="http://www.instagram.com/foyyrjournal" target="_instagram" title="Instagram">
					<img
						src="@/assets/images/ic_instagram.svg"
						:height="socialIconSize"
						:width="socialIconSize"
						alt="Instagram"
					/>
				</a>
				<a
					href="http://www.facebook.com/FOYYR-108628200668301/"
					target="_facebook"
					title="Facebook"
				>
					<img
						src="@/assets/images/ic_facebook.svg"
						:height="socialIconSize"
						:width="socialIconSize"
						alt="Facebook"
					/>
				</a>
				<a href="https://twitter.com/foyyrjournal" target="_twitter" title="Twitter">
					<img
						src="@/assets/images/ic_twitter.svg"
						:height="socialIconSize"
						:width="socialIconSize"
						alt="Twitter"
					/>
				</a>
			</div>
			<div class="footer__info">
				<Logo color="#c4c4d3" secondary size="30" />
				<div class="footer__byline">&copy; Copyright 2020 <b>FOYYR</b>.</div>
			</div>
		</div>
	</footer>
</template>

<script>
import Logo from "../content/Logo.vue";
export default {
	name: "main-footer",
	components: { Logo },
	data: function () {
		return {
			socialIconSize: 24,
		};
	},
};
</script>

<style scoped lang="scss">
.footer {
	font-family: "Inter", sans-serif;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	background-color: #2b2b38;
	color: #c4c4d3;
	padding: 60px 0 40px;
	&__content {
		flex: 1;
	}
	&__email {
		text-align: right;
		margin-bottom: 20px;
		a {
			display: inline-block;
			padding: 3px 8px;
			color: #c4c4d3;
			text-decoration: none;
			font-size: 0.9em;
			&:hover {
				background-color: rgba(#ffe300, 0.9);
				color: #2b2b38;
			}
		}
	}
	&__links {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 25px;
		a {
			&:not(:first-of-type) {
				margin-left: 20px;
			}
		}
	}
	&__info {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	&__byline {
		margin-top: -4px;
		text-align: right;
		flex: 1;
		font-size: 0.6em;
		color: #c4c4d3;
		letter-spacing: 0.03em;
	}
}
</style>
