import axios from "axios";
import { ApiResponse } from "@/models/ApiResponse.js";

class ContactApi {
	constructor() {
		this.httpClient = axios.create({
			baseURL: `${process.env.VUE_APP_API_SERVER}/v${process.env.VUE_APP_API_VERSION}`,
		});
	}

	async saveMessage(message) {
		if (!message || !(message instanceof ContactMessage)) {
			throw Error("you must provide a ContactMessage instance");
		}
		const path = "/contact";
		const res = await this.httpClient.post(path, message);
		if (res.data) {
			const apiResponse = new ApiResponse(res.data);
			if (apiResponse.errors) {
				throw Error("could not save contact message");
			}
		}
		return true;
	}
}

class Contact {
	constructor(name, email, business) {
		this.name = name;
		this.email = email;
		this.business = business;
	}
}

class ContactMessage {
	constructor(contact, message) {
		this.contact = contact;
		this.message = message;
	}
}

export { ContactApi, Contact, ContactMessage };
