import firebase from "firebase/app";
import "firebase/analytics";

class AnalyticsApi {
	constructor(options) {
		const analytics = options && options.analytics;
		if (!analytics) {
			try {
				this.analytics = firebase.analytics();
			} catch (e) {
				console.error(
					"%cCould not configure analytics or analytics is disabled: %s",
					{ color: "red" },
					e,
				);
			}
		} else {
			this.analytics = analytics;
		}
		this.enabled = options && options.enabled;
	}

	trackPage({ name, options }) {
		if (!this.analytics || !this.enabled || !name) {
			return;
		}
		const screenParams = {
			app_name: "web",
			screen_name: name,
			...(options && options.extra),
		};
		const version = options && options.version;
		if (version) {
			screenParams["app_version"] = version;
		}

		this.analytics.setCurrentScreen(name);
		this.analytics.logEvent("page_view");
		this.analytics.logEvent("screen_view", screenParams);
	}
}

export { AnalyticsApi };
