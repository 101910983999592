<template>
	<div class="image-holder" :style="cssVars">
		<div class="fyr-container fyr-container-px">
			<img :src="imageURL" :alt="imageAlt" />
		</div>
	</div>
</template>

<script>
export default {
	name: "image-holder",
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		backgroundColor() {
			return this.componentData && this.componentData.backgroundColor;
		},
		cssVars() {
			const cssVars = {};
			if (this.backgroundColor) {
				cssVars["--fyr-image-bg-color"] = this.backgroundColor;
			}
			return cssVars;
		},
		image() {
			return this.componentData && this.componentData.image;
		},
		imageURL() {
			return this.image && this.image.file && this.image.file.url;
		},
		imageAlt() {
			return this.image && this.componentData.imageAltText;
		},
	},
};
</script>

<style scoped lang="scss">
.image-holder {
	background-color: var(--fyr-image-bg-color);
}
</style>
