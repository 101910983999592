<template>
	<div class="rich-text" :style="cssVars">
		<div :class="containerClasses">
			<div class="rich-text__content" v-html="htmlText"></div>
		</div>
	</div>
</template>

<script>
import { documentToHtmlString } from "@/plugins/contentful/rendering/render_functions.js";

export default {
	name: "rich-text",
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		containerClasses() {
			const classes = [];
			let useStandardContainer = true;
			if (this.componentData && this.componentData.useStandardContainer !== undefined) {
				useStandardContainer = this.componentData.useStandardContainer === true;
			}
			if (useStandardContainer) {
				classes.push("fyr-container", "fyr-container-px");
			}
			return classes;
		},
		cssVars() {
			const cssVars = {};
			if (this.textColor) {
				cssVars["--fyr-rich-text-text-color"] = this.textColor;
			}
			cssVars["--fyr-rich-text-text-align"] = this.textAlign || "center";
			return cssVars;
		},

		htmlText() {
			return (
				this.componentData &&
				this.componentData.text &&
				documentToHtmlString(this.componentData.text)
			);
		},
		textAlign() {
			return this.componentData && this.componentData.textAlign;
		},
		textColor() {
			return this.componentData && this.componentData.textColor;
		},
	},
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/containers";
.rich-text__content {
	text-align: var(--fyr-rich-text-text-align);
	color: var(--fyr-rich-text-text-color);
}
</style>
