<template>
	<div class="heading-group" :style="cssVars">
		<div v-for="(h, k) in headings" :key="k" class="heading-group__heading">
			<Heading :component-data="h" />
		</div>
	</div>
</template>

<script>
import Heading from "@/components/contentful/blocks/Heading.vue";
export default {
	name: "block-heading-group",
	components: { Heading },
	props: {
		componentData: undefined,
		componentOptions: undefined,
	},
	computed: {
		backgroundColor() {
			return this.componentData && this.componentData.backgroundColor;
		},
		cssVars() {
			const cssVars = {};
			if (this.backgroundColor) {
				cssVars["--fyr-heading-group-bg-color"] = this.backgroundColor;
			}
			return cssVars;
		},
		headings() {
			return this.componentData && this.componentData.headings;
		},
	},
};
</script>

<style scoped lang="scss">
.heading-group {
	padding: 40px 0;
	background-color: var(--fyr-heading-group-bg-color);
	::v-deep(.heading) {
		padding: 0;
	}
}
.heading-group__heading {
	&:not(:last-of-type) {
		margin-bottom: 10px;
	}
}
</style>
