export const firebaseConfig = {
	apiKey: "AIzaSyBTmVIiyMt06TJzteTnydYyGLiel-I8hfc",
	authDomain: "foyyr-5d7f4.firebaseapp.com",
	databaseURL: "https://foyyr-5d7f4.firebaseio.com",
	projectId: "foyyr-5d7f4",
	storageBucket: "foyyr-5d7f4.appspot.com",
	messagingSenderId: "620909755357",
	appId: "1:620909755357:web:0501d75096ee9727351434",
	measurementId: "G-S98X889DY8",
};
